<template>
  <div class="row">
    <div class="col">
      <b-card title="Devices">
        <blueprint-data api-route-path="inventory/devices" :fields="fields">
          <template v-slot:default="{ state, item }">
            <b-form-group label="Name" label-for="name">
              <b-form-input id="name" v-model="item.name" :state="state('name')" type="text" placeholder="Name" />
            </b-form-group>
            <a v-if="item.id && !showReplaceDevice" href="#" @click.prevent="showReplaceDevice = true">Replace Device</a>

            <b-form-group v-if="!item.id || showReplaceDevice" label="Device Username" label-for="username">
              <b-form-input id="username" v-model="item.username" :state="state('username')" autocomplete="username" type="text" placeholder="Device Username (System Master)" />
            </b-form-group>
            <b-form-group v-if="!item.id || showReplaceDevice" label="Device Password" label-for="password">
              <b-form-input id="password" v-model="item.password" :state="state('password')" autocomplete="current-password" type="password" placeholder="Password" />
            </b-form-group>
            <b-form-group v-if="!item.id || showReplaceDevice" label="Device Cloud Key" label-for="key">
              <b-form-input id="key" v-model="item.key" :state="state('key')" type="text" placeholder="Device Cloud Key" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showReplaceDevice: false,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'deviceId',
          sortable: true,
        },
      ],
    };
  },
};
</script>
